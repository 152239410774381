import React, { Component, Suspense, useContext } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import {Redirect} from 'react-router-dom';
import {contextAuth} from 'src/context/Auth';
import LoadingPage from 'src/components/loadingPage';
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const EsqueciSenha = React.lazy(() => import('./views/pages/esquecisenha'))
const NovaSenha = React.lazy(() => import('./views/pages/novasenha'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={<LoadingPage/>}>
          <Switch>
            <Loading>
              <Route exact path="/login" name="Autenticação" render={(props) => <Login {...props} />} />
              <Route exact path="/esquecisenha" name="Esqueci a senha" render={(props) => <EsqueciSenha {...props} />} />
              <Route exact path="/novasenha" name="Nova senha" render={(props) => <NovaSenha {...props} />} />
              <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
              <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
              <AuthRoute>
                <Route path="/" name="Início" render={(props) => <DefaultLayout {...props} />} />
              </AuthRoute>
            </Loading>
          </Switch>
        </Suspense>
      </HashRouter>
    )
  }
}
function Loading ({children}) {
  const {loading} = useContext(contextAuth);
  if(loading){
    return (<><LoadingPage/></>)
  }else{
    return (<>{children}</>)
  }
}
function AuthRoute({children}){
  const {authenticated} = useContext(contextAuth);
  if(authenticated){
    return (<>{children}</>)
  }else{
    return (<Redirect to="/login" />)
  }
}

export default App
