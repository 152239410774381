import {useState, useEffect} from 'react';
import api from '../../api';

export default function useAuth(props){
    const [loading, setLoading] = useState(true);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    api.interceptors.response.use(function (response) {
      return response;
    },function (error){
      switch(error.response.statusText){
        case 'Unauthorized':
          localStorage.removeItem('token');
          api.defaults.headers.Authorization = undefined;
          setAuthenticated(false);
          setLoading(false);
        break;
      }
      return Promise.reject(error)
    });
    useEffect(async() => {
        const token = localStorage.getItem('token');
        setLoading(true);
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`;
            await api.get('/sso').then((data)=>{
              if(data){
                localStorage.setItem('user', JSON.stringify(data.data.user));
                localStorage.setItem('group', JSON.stringify(data.data.grupo));
              }
              setAuthenticated(true);
            }).catch((error)=>{
              localStorage.removeItem('token');
              api.defaults.headers.Authorization = undefined;
              setAuthenticated(false);
              setLoading(false);
            })
          }
          setLoading(false);
      }, []);
     async function handleLogin(username, password, tokenRE, history) {
       setLoadingBtn(true);
                await api.post('/sso',{
                usuario: username,
                senha: password,
                token_recaption: tokenRE
            }).then((data)=>{
              if(data){
                localStorage.setItem('token', JSON.stringify(data.data.token));
                localStorage.setItem('user', JSON.stringify(data.data.user));
                localStorage.setItem('group', JSON.stringify(data.data.grupo));
                api.defaults.headers.Authorization = `Bearer ${data.data.token}`;
                setAuthenticated(true);
                history.push('/painel');
                setLoadingBtn(false);
              }
              setLoadingBtn(false);
            }).catch((error)=>{
              setLoadingBtn(false);
            })
      }

      async function handleLogout() {
        setLoadingBtn(true);
        await api.get('/sso/logout');
        removeAuthSystem();
        setLoadingBtn(false);
      }
      function removeAuthSystem() {
        setAuthenticated(false);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('group');
        api.defaults.headers.Authorization = undefined;
      }

      return { loading, handleLogin, handleLogout, authenticated,loadingBtn, removeAuthSystem };
}

