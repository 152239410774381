import axios from 'axios';
import { toast } from 'react-toastify';

//export const url = 'https://api.kingcnc.com.br/api';
export const url = 'http://localhost:8000/api';
const api = axios.create({
  baseURL: url
});
var alerta;
api.interceptors.request.use(function (request){
  toast.dismiss(alerta);
  alerta = toast.loading("Carregando...",{position:"bottom-right"});
  return request;
})
api.interceptors.response.use(function (response) {
  toast.dismiss(alerta);
  if(response.data.msg){
    if(response.data.status == 'success'){
      response.data.msg.forEach(msg => {
        toast.success(msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    }
  }
  return response
},function (error){
  switch(error.response.statusText){
    case 'Network Error':
      toast.dismiss(alerta);
      toast.update(alerta,{
        render: "Falha na comunicação com o servidor",
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        isLoading: false,
      });
    break;
    case 'Unauthorized':
      toast.dismiss(alerta);
      toast.update(alerta,{
        render: "Token não autorizado",
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        isLoading: false,
      });
    break;
    default:
      if(error.response.data.msg){
        //verify if is an array
        if(Array.isArray(error.response.data.msg)){
          error.response.data.msg.forEach(msg => {
            toast.dismiss(alerta);
            toast.error(msg, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
        }else{
          toast.dismiss(alerta);
          toast.error(error.response.data.msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }else{
        toast.update(alerta,{
          render: 'Falha na comunicação com o servidor',
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          isLoading: false,
        });
      }
    break;
  }
  return Promise.reject(error)
});

export default api
