import React, {createContext} from 'react';
import useAuth from './hooks/Auth';

const contextAuth = createContext();

function AuthProvider({children}){
    const {
        authenticated, loading, handleLogin, handleLogout, loadingBtn, removeAuthSystem
    } = useAuth();


    return (
        <contextAuth.Provider value={{authenticated, loading ,handleLogin, handleLogout, loadingBtn, removeAuthSystem}}>{children}</contextAuth.Provider>
    )
}
export {AuthProvider, contextAuth};
